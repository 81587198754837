@import '~normalize.css';
@import 'theme.scss';

html,
body {
  height: 100%;
  width: 100%;
}

.inset {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 768px) {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.match-parent {
  height: 100%;
  width: 100%;
}

.firebase-emulator-warning {
  display: none;
}

.btn-primary {
  background-color: $primary-dark-color;
  border-color: $primary-dark-color;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-dark-hover-color;
    border-color: $primary-dark-hover-color;
  }

  &:disabled {
    background-color: $primary-disabled-color;
    border-color: $primary-disabled-color;
  }
}

.btn-outline-secondary {
  border-color: $primary-color;
  color: $primary-color;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}
