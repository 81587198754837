// Base theme
$primary-color: #66b040;
$primary-dark-color: #658f36;
$accent-color: #b3562e;
$text-color: #000000;

// Hover theme
$primary-dark-hover-color: #53752d;

// Disabled theme
$primary-disabled-color: #c4e4b4;

// Media query breakpoints
$breakpoint-small: 600px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-extra-large: 1200px;
